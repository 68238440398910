<template>
    <div class="body">
        <div class="container">
            <div class="header">
                <div class="tabs">
                    <div class="tab-item cur">全部</div>
                   <!-- <div class="tab-item">进行中</div>
                    <div class="tab-item">已完成</div> -->
                </div>
                <div class="line full-width"></div>
            </div>
            <div class="task-list full-width">
                <van-list
                  v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                >
                    <van-cell-group>
                      <van-cell
                        is-link
                        @click="toDetail(item.id)"
                        v-for="item in list"
                        :key="item.id"
                        label="考勤统计表"
                      >
                      <template slot="title">
                          <strong class="custom-text" style="font-size: 14px;">
                          {{item.batch}}</strong>
                          <van-tag 
                            plain
                            color="#ff976a"
                            style="margin-left: 20px;">
                          {{getStatusText(item.status)}}
                          </van-tag>
                        </template>
                      </van-cell>
                    </van-cell-group>
                </van-list>
            </div>
        </div>
    </div>
</template>

<script>
    import {toast , checkLogin} from '@/utils/common.js';
    import { getMyAttendance} from '@/api/task.js';
    import { mapState } from 'vuex';
    var params = {
        page: 1,
        limit: 30
    }
    export default{
        data(){
            return {
                value: 20,
                list: null,
                loading: false,
                finished: false
            }
        },
        activated(){
            checkLogin().then(()=> {
                this.getList();
            })
            this.hideTab();
         },
         computed: mapState(['token']),
         methods:{
            toDetail(id){
                this.$router.push(`/hr/attendance/detail?id=${id}`);
            },
            getList(){
                this.loading = true;
                getMyAttendance(params).then((ret)=>{
                    this.list = ret.data.list;
                    this.loading = false;
                    this.finished = true;
                }).catch((err)=>{
                    toast(err.msg || '获取失败');
                });
             },
            getStatusText(status){
                switch (status){
                    case 0:
                        return '未确认';
                    case 1:
                        return '已确认';
                    case -1:
                        return '有异议';
                    default:
                        return '未知';
                 }
             },
             hideTab(){
                 //console.log(document.querySelector('#vantab'));
                 document.querySelector('#vantab').classList.add('tab-hide');
             }
         },
    }
</script>

<style scoped>
    @import url('../../assets/css/common.css');
    .container{
        bottom: 50px;
    }
    .line{
        height: 1px;
        background-color: #ccc;
        margin: 10px 0 10px;
    }
    .tabs{
        display: flex;
        margin: 10px 0 6px;
        font-size: 14px;
        font-weight: 400;
    }
    .tab-item{
        margin-right: 10px;
    }
    .tab-item.cur{
        color: #465acd;
    }
    .title-class, .value-class, .label-class{
        font-size: 14px;
    }
    .van-tabbar--fixed{
        display: none !important;
    }
</style>